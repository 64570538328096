/* Slider */

$slick-opacity-on-hover: 1 !default;
$slick-dot-size: 2rem !default;
$slick-dot-color: black !default;
$slick-opacity-not-active: 0.25 !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-opacity-default: 0.75 !default;
$slick-arrow-color: black !default;
$slick-prev-character: fa-content($fa-var-angle-left) !default;
$slick-next-character: fa-content($fa-var-angle-right) !default;




.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    &:focus{ outline: none; }
    &:hover{ cursor: pointer; }
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}



/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 13px;
        width: 13px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 13px;
            width: 13px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                    -webkit-transition: all 300ms ease-out;
                    transition: all 300ms ease-out;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 13px;
                height: 13px;
                background-color: #000;
                border-radius: 50%;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                -webkit-transition: all 300ms ease-out;
                transition: all 300ms ease-out;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 40px;
    width: 33px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: rgba(255,255,255,0.5);
    color: rgba(255,255,255,0.5);
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    border-radius: 2px;
    outline: none;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    z-index: 1;
    &:hover, &:focus {
        outline: none;
        background: rgba(255,255,255,0.3);
        color: rgba(255,255,255,0.3);
        -webkit-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-size: 20px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {
    left: 0;
    @extend %fa-icon;
    @extend .fas;
    @include media-breakpoint-down(xs){
      left: 0;
    }
    [dir="rtl"] & {
        left: auto;
        right: 0;
        @include media-breakpoint-down(xs){
          right: 0;
        }
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

.slick-next {
    right: 0;
    @extend %fa-icon;
    @extend .fas;
    @include media-breakpoint-down(xs){
      right: 0;
    }
    [dir="rtl"] & {
        left: 0;
        right: auto;
        @include media-breakpoint-down(xs){
          left: 0;
        }
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}


.slider-thumbs{
  .slick-slide{
    &.slick-active{
      position: relative;
      &::after{
        content: "";
        width: 0%;
        height: 3px;
        background-color: $white;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
        opacity: .7;
        @extend .transition;
      }
      &.slick-current{
          &::after{
              width: 100%;
              left: 0;
              right: auto;
              @extend .transition;
          }
      }
    }
  }
}
