//******************************Loader******************************//
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-js .loader {
  display: none;
}
.loader__spinner {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 4px solid #F2F2F2;
  border-top-color: $primary;
  border-left-color: $primary;
  animation: spin .8s linear infinite;
  z-index: 11;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader__background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
  z-index: 10;
  left: 0;
}
/* Loaded styles */
.body--loaded .loader__background {
  opacity: 0;
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.body--loaded .loader__spinner {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.body--loaded .loader {
  visibility: hidden;
  transition: all 0.3s 1s ease-out;
}
