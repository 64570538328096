.loader-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.loader {
    display: block;
    margin: 20px auto 0;
}