.modal-dialog {
  margin: 4rem auto;
  max-width: 55rem;
}
.modal{
  background-color: rgba(0,0,0,.8);
  .modal-content{
    border-radius: 4px;
    border: none;
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
    color: #fff;
  }
  .close{
    margin: 1rem;
    color: #fff;
    opacity: 1;
    background: transparent;
    border: none;
    &:focus{
      outline: none;
    }
  }
  .modal-header{
    padding-right: 2rem;
    z-index: 1;
    color: #ffff;
    font-size: 4rem;
    border-bottom: 1px solid #E0E0E0;
  }
  .modal-body{
    padding: 1rem 1rem 0 1rem;
    h2, h1, h3, h4, h5, h6{ color: #fff; }
    p{ color: #fff; }
    img{ width: 100%; }
    .light-color{ margin: 1rem 0; }
  }
}

.mobile-menu{
  &.show {
    display: block;
  }
  .modal-header{
    display: block;
    border-bottom: 0;
    padding: 0;
    .close{
      span{
        font-size: 2.7rem;
      }
    }
  }
  .modal-dialog{
      margin: 0 auto;
  }
}
