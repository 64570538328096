
.works__gallery{
  position: relative;
  margin: 0.3rem .5rem;
  figcaption::before {
    position: absolute;
    right: -7rem;
    bottom: -7rem;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: inset 0 0 0 900px rgba(0, 0, 0, 0.4);
    content: '';
    opacity: 0;
    -webkit-transform: scale3d(0.5,0.5,1);
    transform: scale3d(0.5,0.5,1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }
  img{
    border-radius: 50%;
    width: 100%;
  }
  p {
   position: absolute;
   right: 1rem;
   bottom: 1.25rem;
   padding: 0 1.5em 1.5em 0;
   width: 10rem;
   text-align: left;
   opacity: 0;
   color: $white;
   z-index: 1;
   -webkit-transform: translate3d(20px,20px,0);
   transform: translate3d(20px,20px,0);
   a{
     text-decoration: underline;
     color: $primary;
     text-transform: uppercase;
     font-size: .95rem;
     letter-spacing: 0.1px;
     font-style: normal;
     margin-top: .5rem;
     display: block;
     &:hover{ cursor: pointer; color: $white; }
   }
 }
 .works__gallery--effect{
   position: relative;
   overflow: hidden;
   border-radius: 50%;
 }
  &::after{
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: "";
    left: -7px;
    padding: 6px;
    z-index: -1;
    opacity: 0;
    top: -9px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: opacity 0.2s, box-shadow 0.2s;
    -moz-transition: opacity 0.2s, box-shadow 0.2s;
    transition: opacity 0.2s, box-shadow 0.2s;
  }
  &:hover{
    &::after{
      box-shadow: 3px 3px 0 $black;
      box-shadow: 3px 3px 0 rgba(0,0,0,.8);
      opacity: 1;
      -webkit-transition: opacity 0.2s, box-shadow 0.2s;
      -moz-transition: opacity 0.2s, box-shadow 0.2s;
      transition: opacity 0.2s, box-shadow 0.2s;
    }
    figcaption::before {
      opacity: 1;
      -webkit-transform: scale3d(1,1,1);
      transform: scale3d(1,1,1);
    }
    p {
      opacity: 1;
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }
  }
}
