.data-video{
  .hero-home,
  .service,
  .about{
    background-size: 0;
    &::after{
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .hero-home{
    background-size: cover !important; 
  }
  video{
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    height: auto;
  }
}
