.subpage{
  font-weight: $font-weight-normal;
  padding-bottom: 4rem;
  letter-spacing: 0.5px;
  font-style: normal;
  margin-top: 11rem;
  p{ font-style: normal; opacity: .75; }
  @include media-breakpoint-down(md){
    padding-bottom: 2rem;
  }
}

.title-post{
  p{
    font-style: italic;
    opacity: .6;
  }
}

.author-bio{
  .author-title{
    h2{
      font-size: 1rem;
      letter-spacing: 0;
      margin: 0;
    }
    p{
      font-size: .8rem;
      font-style: normal;
      font-family: $font-family-base;
      font-weight: $font-weight-normal;
      margin-bottom: 0;
    }
  }
}

.bg-light{
  position: relative;
  &::after{
    position: absolute;
    width: 90%;
    height: 90%;
    right: -10%;
    bottom: -10%;
    content: "";
    background-color: $primary;
    z-index: -1;
  }
}

.banner-gray-behing{
  position: relative;
  &::after{
    position: absolute;
    content: "";
    left: 0;
    bottom: -3.125rem;
    height: 70%;
    width: 100%;
    background-color: #fbfbfb;
    z-index: -1;
  }
}

.box-shadow{
  -webkit-box-shadow: 9px 9px 7px -2px rgba(0,0,0,0.1);
  -moz-box-shadow: 9px 9px 7px -2px rgba(0,0,0,0.1);
  box-shadow: 9px 9px 7px -2px rgba(0,0,0,0.1);
}
