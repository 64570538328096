.content-single{
  a{
    color: $primary;
    &:hover{ color: $primary; opacity: .8; }
  }
}
.latest-post{
  position: relative;
  z-index: 1;
  margin-bottom: 4rem;
  @include media-breakpoint-down(md){
      margin-bottom: 0;
  }
  .latest-post-item{
    @extend .col-6;
    @extend .col-md-3;
  }
  img{ margin-bottom: 1rem; border-radius: 2px; }
  a,p{
    font-style: normal;
  }
  a{
    display: block;
    position: relative;
    &:hover{
      @extend .transition;
      .latest-post__title{
        color: $primary;
        @extend .transition;
      }
      &::after{
        width: 100%;
        right: auto;
        left: 0;
        @extend .transition;
      }
    }
    &::after{
      content: "";
      width: 0%;
      height: 4px;
      background-color: $primary;
      position: absolute;
      right: 0;
      top: -3px;
      z-index: 0;
      opacity: 0.9;
      @extend .transition;
    }
  }
  p{
    font-size: 0.87rem;
    line-height: 1.6rem;
    letter-spacing: .02rem;
    position: relative;
    padding-bottom: 1rem;
  }
}


.blog-page{
  position: relative;
  &::after{
    position: absolute;
    content: "";
    left: 0;
    bottom: -3.125rem;
    height: 70%;
    width: 100%;
    background-color: #fbfbfb;
    z-index: 0;
  }
  .latest-post{
    img{
      -webkit-box-shadow: 9px 9px 7px -2px rgba(0,0,0,0.12);
      -moz-box-shadow: 9px 9px 7px -2px rgba(0,0,0,0.12);
      box-shadow: 9px 9px 7px -2px rgba(0,0,0,0.12);
    }
    a{
      color: $dark;
      text-decoration: none;
    }
  }
}

.filter-posts{
  border-bottom: 1px solid #f1f1f1;
  .nav-item{
    margin-right: 2rem;
    @include media-breakpoint-down(xs){
      margin-right: 0;
      a{ font-size: .8rem; }
      &.active{
        padding: 0.5rem;
      }
    }
    a:not(.active){
      color: $dark;
    }
  }
}

.category, .date, .link-read-more{
  text-transform: uppercase;
  font-size: .875rem;
  color: $primary;
  i{ vertical-align: text-bottom; }
}
.list-posts{
  margin-top: 6rem;
  margin-bottom: 5rem;
  @include media-breakpoint-down(md){
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .description-post{
    margin-bottom: 6rem;
    align-items: center;
    @include media-breakpoint-down(xs){
      align-items: flex-start !important;
      margin-bottom: 3rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid #f1f1f1;
    }
    figure{
      -webkit-box-shadow: 9px 9px 20px 7px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 9px 9px 20px 7px rgba(0, 0, 0, 0.1);
      box-shadow: 9px 9px 20px 7px rgba(0, 0, 0, 0.1);
    }
    .category, .link-read-more{
      &:hover{
        cursor: pointer;
      }
    }
    .link-read-more{ font-style: italic; }
    a{
      color: inherit;
      font-style: normal;
    }
  }
}


.pin-wrapper{
  position: absolute;
  left: -5rem;
}

.share-post{
  text-align: center;
  @include media-breakpoint-down(lg){
    text-align: left;
    margin-bottom: 1rem !important;
  }
 li{
    padding-left: 0 !important;
    margin-bottom: .8rem;
    @include media-breakpoint-down(lg){
      display: inline-block;
    }
    &::before{
      display: none;
    }
    &.share-post--text{
      font-size: .685rem;
      text-transform: uppercase;
      opacity: .8;
      margin-bottom: 0.3rem;
    }
  }
  a{
    background-color: $white;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    text-decoration: none;
    border: 1px solid #000;
    &:hover{
      transition: all .2s ease-in-out;
      transform: scale(1.2);
      background-color: $black;
      opacity: .95;
      i{ opacity: 1; color: $white; }
    }
    i{
      color: $black;
      font-size: 1.2rem;
      text-decoration: none;
    }
  }
}
